<template>
  <div class="wrap">
    <div class="full-flex">
      <div class="top-tip">
        国家邮政局最新规定，寄快递须实名登记
      </div>
      <div class="auth-wrap">
        <div class="auth">
          <img src="https://cdn.kuaidi100.com/images/wxapp/kuaidi100/icoIdentity.png" alt="">
          <p>上传身份证正面照片</p>
          <input type="file" accept="image/png,image/jpeg,image/jpg,image/gif,image.bmp" :value="img" @change="reconize">
        </div>
      </div>
      <div class="auth-brief">
        <h3>请上传清晰的证件图像，以便快速通过</h3>
        <img src="https://cdn.kuaidi100.com/images/wxapp/kuaidi100/icoIdentityExp.png">
        <p class="txt">
          <span class="corr">标准</span>
          <span>边框缺少</span>
          <span>照片模糊</span>
          <span>闪光强烈</span>
        </p>
      </div>
    </div>
    <slide class="slide" :show="page == 'info' || page == 'rule'">
      <div class="auth-info">
        <div class="list-info">
          <label>姓名</label>
          <span>{{ authData.name }}</span>
        </div>
        <div class="list-info">
          <label>身份证号</label>
          <span>{{ authData.id }}</span>
        </div>
        <p class="go-auth center" @click="reset">
          如信息有误，可重新识别
        </p>
      </div>
      <div class="footer">
        <div class="agree" style="display: block;">
          <i :class="{active:agree}" @click="agree = !agree"></i>
          我同意授权快递员在取件前查验我的真实姓名及身份证号码。<a @click="pushstate('rule')">点击查看详细规则</a>
        </div>
        <div class="global-btn" @click="auth">
          信息正确，提交
        </div>
      </div>
    </slide>
    <slide class="slide" :show="page == 'rule'">
      <div class="article">
        <div class="item">
          <h3>寄件小提醒</h3>
          <p>为了加强寄递渠道安全管理，中央综治办、国家邮政局等15部门联合发文，要求在寄快递渠道100%落实“收寄验视+实名收寄+过机安检”三项措施。</p>
        </div>
        <div class="item">
          <h3>实名认证好处</h3>
          <p>做过实名认证，后续使用所有关联的账号（如绑定的微信，QQ等）在快递100各平台（APP，小程序，微信公众号，手机站，电脑站）均不在需要出示身份证件，省心又方便。</p>
        </div>
        <div class="item">
          <h3>信息安全保障</h3>
          <p>1. 身份信息不会打印在快递100面单上，不用担心身份证照片被非法使用。</p>
          <p>2. 快递100执行严格的信息安全制度，对个人信息才去银行级的加密，并承诺不会将客户的个人信息透漏给任何第三方机构及个人。</p>
        </div>
      </div>
    </slide>
  </div>
</template>
<script>
import Compressor from "lib/compressor"

export default {
  data: function(){
    return {
      page: 0,
      loading: {text: '奋力加载中', show: false},
      origin: {cardno:'',realname:''},
      authData:{},
      img: '',
      agree: true
    }
  },
  created() {
    this.login(null, () => {
      this.goLogin()
    })
    window.addEventListener("popstate",(e) => {
      if(e.state && e.state.page) {
        this.page = e.state.page
      } else {
        this.page = 0
        this.img = ""
      }
    })
  },
  methods: {
    validateFile(files) {
      if(files.length){
        var file = files[0]
        if(!/jpeg|jpg|png|bmp|gif/i.test(file.type)) {
          this.$toast('图片格式仅支持jpg,png,bmp,gif', 1000)
          return false
        }
        return true
      }
      return false
    },
    reset() {
      this.img = ''
      this.$router.go(-1)
    },
    pushstate(page){
      window.history.pushState({page: page}, null)
      this.page = page
    },
    reconize(event){
      if(this.validateFile(event.target.files)){
        this.compress(event.target.files[0])
      }
    },
    compress(file) {
      this.loading = this.$loading("正在识别，请稍后...")
      try {
        new Compressor(file, {
          quality: Math.min(1 / (file.size / 1048576), 0.6),
          maxWidth: 800,
          success: (result) => {
            this.upload(result)
          },
          error: () => {
            this.upload(file)
          }
        })
      } catch(e) {
        this.upload(file)
      }
   
    },
    upload(file){
      // return
      var form = new FormData()
      form.append('file', file, file.name)
      this.$http.upload('/apicenter/xcx.do?method=uploadidcard', {
        data: form,
        timeout: 30000
      }).then(result => {
        this.img = ""
        this.loading.hide()
        if(result.id){
          this.authData = result
          this.pushstate('info')
        } else {
          this.$toast('图片上传失败')
        }
      }).catch(res => {
        this.$toast(res.message || "图片上传失败")
        this.loading.hide()
      })
    },
    auth(){
      if(!this.agree) {
        return this.$toast("你没有选择同意授权信息")
      }
      this.loading = this.$loading("正在提交...")
      this.$http.post('/apicenter/order.do?method=addUserCardInfo', {
        data: {
          name: this.authData.name,
          birthday: this.authData.birth,
          nation: this.authData.nation,
          idaddress:this.authData.address,
          cardno: this.authData.id
        }
      }).then(() => {
        this.loading.hide()
        this.$toast("实名认证成功")
        this.$store.commit("setAuth", true)
        this.$router.go(-2)
      }).catch(() => {
        this.loading.hide()
      })
    }
  }
}
</script>
<style src="./index.css" scoped></style>