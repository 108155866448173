import toBlob from 'blueimp-canvas-to-blob'
import DEFAULTS from './defaults'

import {
  ERR,
  ArrayBuffer,
  FileReader,
  URL
} from './constants'
import {
  isBlob,
  arrayBufferToDataURL,
  imageTypeToExtension,
  isImageType,
  normalizeDecimalNumber,
  parseOrientation,
  resetAndGetOrientation,
} from './helpers'


export default class Compressor {
  // 文件，配置项
  constructor(file, options) {
    this.file = file
    this.options = {
      ...DEFAULTS,
      ...options,
    };
    this.aborted = false
    this.result = null
    this.image = null 
    this.init()
  }

  check() {
    const file = this.file

    if (!isBlob(file)) {
      return this.fail(ERR.NOTBLOB)
    }

    if (!URL || !FileReader) {
      return this.fail(ERR.NOTSUPPORT)
    }

    if (!isImageType(file.type)) {
      return this.fail(ERR.ERRTYPE)
    }

    return true
  }

  init() {
    if(this.check() !== true) return
    this.create()
  }

  create() {
    const { options, file } = this
    const mimeType = file.type
    if (!ArrayBuffer) {
      options.checkOrientation = false
    }
    if (URL && !options.checkOrientation) {
      this.load({
        url: URL.createObjectURL(file)
      });
    } else {
      const reader = new FileReader()
      const checkOrientation = options.checkOrientation && mimeType === 'image/jpeg'

      this.reader = reader
      reader.onload = ({ target }) => {
        const { result } = target
        const data = {}
        if (checkOrientation) {//ios下的纠正图片方向，仅支持jpg格式
          const orientation = resetAndGetOrientation(result)

          if (orientation > 1 || !URL) {
            data.url = arrayBufferToDataURL(result, mimeType)
            if (orientation > 1) {
              Object.assign(data, parseOrientation(orientation))
            }
          } else {
            data.url = URL.createObjectURL(file)
          }
        } else {
          data.url = result
        }

        this.load(data)
      };
      reader.onabort = reader.onerror = () => {
        this.fail(`FileReader: ${ERR.IMAGE_ABORT}`)
      }
      reader.onloadend = () => {
        this.reader = null
      }

      if (checkOrientation) {
        reader.readAsArrayBuffer(file)
      } else {
        reader.readAsDataURL(file)
      }
    }
  }

  load(data) {
    const file = this.file
    let image = this.image = new Image()
    image.crossOrigin = 'anonymous'
    image.alt = file.name
    image.src = data.url

    image.onload = () => {
      this.draw({
        ...data,
        naturalWidth: image.naturalWidth,
        naturalHeight: image.naturalHeight,
      })
    }

    image.onerror = image.onabort = () => {
      this.fail(ERR.IMAGE_ABORT)
    }

  }

  draw({
    naturalWidth,
    naturalHeight,
    rotate = 0,
    scaleX = 1,
    scaleY = 1,
  }) {
    const { file, image, options } = this
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const aspectRatio = naturalWidth / naturalHeight
    const is90DegreesRotated = Math.abs(rotate) % 180 === 90
    let maxWidth = Math.max(options.maxWidth, 0) || Infinity
    let maxHeight = Math.max(options.maxHeight, 0) || Infinity
    let minWidth = Math.max(options.minWidth, 0) || 0
    let minHeight = Math.max(options.minHeight, 0) || 0
    let width = Math.max(options.width, 0) || naturalWidth
    let height = Math.max(options.height, 0) || naturalHeight

    if (is90DegreesRotated) {
      [maxWidth, maxHeight] = [maxHeight, maxWidth]
      ;[minWidth, minHeight] = [minHeight, minWidth]
      ;[width, height] = [height, width]
    }

    if (maxWidth < Infinity && maxHeight < Infinity) {
      if (maxHeight * aspectRatio > maxWidth) {
        maxHeight = maxWidth / aspectRatio
      } else {
        maxWidth = maxHeight * aspectRatio
      }
    } else if (maxWidth < Infinity) {
      maxHeight = maxWidth / aspectRatio
    } else if (maxHeight < Infinity) {
      maxWidth = maxHeight * aspectRatio
    }

    if (minWidth > 0 && minHeight > 0) {
      if (minHeight * aspectRatio > minWidth) {
        minHeight = minWidth / aspectRatio
      } else {
        minWidth = minHeight * aspectRatio
      }
    } else if (minWidth > 0) {
      minHeight = minWidth / aspectRatio
    } else if (minHeight > 0) {
      minWidth = minHeight * aspectRatio
    }

    if (height * aspectRatio > width) {
      height = width / aspectRatio
    } else {
      width = height * aspectRatio
    }

    width = Math.floor(normalizeDecimalNumber(Math.min(Math.max(width, minWidth), maxWidth)))
    height = Math.floor(normalizeDecimalNumber(Math.min(Math.max(height, minHeight), maxHeight)))

    const destX = -width / 2
    const destY = -height / 2
    const destWidth = width
    const destHeight = height

    if (is90DegreesRotated) {
      [width, height] = [height, width]
    }

    canvas.width = width
    canvas.height = height

    if (!isImageType(options.mimeType)) {
      options.mimeType = file.type
    }

    let fillStyle = 'transparent'
    if (file.size > options.convertSize && options.mimeType === 'image/png') {
      fillStyle = '#fff'
      options.mimeType = 'image/jpeg'
    }

    context.fillStyle = fillStyle
    context.fillRect(0, 0, width, height)

    if (options.beforeDraw) {
      options.beforeDraw.call(this, context, canvas)
    }

    if (this.aborted) {
      return
    }

    context.save()
    context.translate(width / 2, height / 2)
    context.rotate((rotate * Math.PI) / 180)
    context.scale(scaleX, scaleY)
    context.drawImage(image, destX, destY, destWidth, destHeight)
    context.restore()

    if (options.drew) {
      options.drew.call(this, context, canvas)
    }

    if (this.aborted) {
      return
    }

    const done = (result) => {
      if (!this.aborted) {
        this.done({
          naturalWidth,
          naturalHeight,
          result,
        });
      }
    };
    this.base64 = canvas.toDataURL(options.mimeType, options.quality)
    if (canvas.toBlob) {
      canvas.toBlob(done, options.mimeType, options.quality)
    } else {
      done(toBlob(this.base64))
    }
  }

  done({
    naturalWidth,
    naturalHeight,
    result,
  }) {
    const { file, image, options } = this

    if (URL && !options.checkOrientation) {
      URL.revokeObjectURL(image.src)
    }

    if (result) {
      if (options.strict && result.size > file.size && options.mimeType === file.type && !(
        options.width > naturalWidth
        || options.height > naturalHeight
        || options.minWidth > naturalWidth
        || options.minHeight > naturalHeight
      )) {
        result = file
      } else {
        const date = new Date()

        result.lastModified = date.getTime()
        result.lastModifiedDate = date
        result.name = file.name

        if (result.name && result.type !== file.type) {
          result.name = result.name.replace(
            /\.\w+$/,
            imageTypeToExtension(result.type),
          )
        }
      }
    } else {
      result = file
    }

    this.result = result

    if (options.success) {
      options.success.call(this, result, this.base64)
    }
  }

  fail(err) {
    const { options } = this

    if (options.error) {
      options.error.call(this, err)
    } else {
      throw new Error(err.message)
    }
  }

  abort() {
    if (!this.aborted) {
      this.aborted = true;

      if (this.reader) {
        this.reader.abort();
      } else if (!this.image.complete) {
        this.image.onload = null
        this.image.onabort()
      } else {
        this.fail(ERR.ABORT)
      }
    }
  }

  static setDefaults(options) {
    Object.assign(DEFAULTS, options)
  }
}
