export const ERR = {
  NOTBLOB: {
    code: 1001,
    message: "请传入一个File或者Blob对象"
  },
  ERRTYPE: {
    code: 1002,
    message: "请传入图片类型的文件"
  },
  NOTSUPPORT: {
    code: 1003,
    message: "浏览器不支持图片压缩"
  },
  IMAGE_ABORT: {
    code: 1004,
    message: "图片加载失败"
  },
  ABORT: {
    code: 1005, 
    message: '压缩进程中断'
  }
}

export const WINDOW = window || {}

export const URL = WINDOW.URL || WINDOW.webkitURL

export const { ArrayBuffer, FileReader } = WINDOW