export default {
  strict: true, //原图更小时是否输出原图
  checkOrientation: true, // 是否纠正ios下的方向问题
  maxWidth: Infinity, // 图片最大宽度
  maxHeight: Infinity, // 图片最大高度
  minWidth: 0, // 最小宽度
  minHeight: 0, // 最小高度
  width: undefined, // 输出宽度，默认为原图
  height: undefined, // 输出高度，默认为原图
  quality: 0.8, // 质量，仅限于jpg和webp
  mimeType: 'auto', // 输出的图片类型，默认为原类型
  convertSize: 5000000, // 超过5M会被转为jpg

  /**
   * 绘制画布前的钩子
   * function (context, canvas) {
   *   context.fillStyle = '#fff';
   * }
   */
  beforeDraw: null, 

  /**
   * 绘制完成的钩子
   * function (context, canvas) {
   *   context.filter = 'grayscale(100%)';
   * }
   */
  drew: null,

  /**
   * 压缩成功回调
   * function (file) {
   *   console.log(file);
   * }
   */
  success: null,

  /**
   * 压缩失败回调
   * function (err) {
   *   console.log(err.message);
   * }
   */
  error: null,
}
